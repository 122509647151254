import React, { Component } from 'react'

import ButtonLink from '../components/button-link';
import Layout from '../components/layout'
import PageSection from '../components/page-section'
import SectionTitle from '../components/section-title'


class NewsletterSuccessPage extends Component {
	render () {
		return (
			<Layout>

				<PageSection>
					<SectionTitle
						title="Tack"
					/>
					<p>
						Tack för din registrering.
					</p>

					<ButtonLink
						to="/"
						color="#666"
						hoverColor="#fff"
					>Tillbaka</ButtonLink>
				</PageSection>

			</Layout>
		)
	}
}

export default NewsletterSuccessPage
